export const projectsPlaceholder = [
  { id: 100000001, photo: { photoUrl: '' } },
  { id: 100000002, photo: { photoUrl: '' } },
  { id: 100000003, photo: { photoUrl: '' } },
  { id: 100000004, photo: { photoUrl: '' } },
  { id: 100000005, photo: { photoUrl: '' } },
  { id: 100000006, photo: { photoUrl: '' } },
  { id: 100000007, photo: { photoUrl: '' } },
  { id: 100000008, photo: { photoUrl: '' } },
  { id: 100000009, photo: { photoUrl: '' } },
  { id: 100000010, photo: { photoUrl: '' } },
  { id: 100000011, photo: { photoUrl: '' } },
  { id: 100000012, photo: { photoUrl: '' } },
  { id: 100000013, photo: { photoUrl: '' } },
  { id: 100000014, photo: { photoUrl: '' } },
  { id: 100000015, photo: { photoUrl: '' } },
  { id: 100000016, photo: { photoUrl: '' } }
];
